<template>
    <div class="fristcon" v-if="infoList">
 
   <div class="fristcon-tit" >
        {{projactNanme}}_{{ historyname }}
      </div>
      <div>
      <div class="fristcon-cen flex">
        <!-- 顶部左边 -->
        <div class="fristcon-cen-left">
          <div class="fristcon-cen-left-tit">
            {{titname}}
          </div>
              <div class="btnarr">
            <div class="fristcon-cen-left-btn flex align-cen">
            <div class="text">SP</div>
            <div class="num ma-auto" @click="tocomment('SP', '#1799cd',0)">{{infoList[node].SP }}</div>
          </div>
           <div class="fristcon-cen-left-btn flex align-cen">
            <div class="text">PV</div>
            <div class="num ma-auto" @click="tocomment('PV', '#0de0ac',1)">{{infoList[node].PV }}</div>
          </div>
           <div class="fristcon-cen-left-btn flex align-cen">
            <div class="text">AV</div>
            <div class="num ma-auto" @click="tocomment('AV', '#ec163e',2)">{{infoList[node].AV }}</div>
          </div>
        </div>
        <div class="btnarr1">
          <div class="btnarr1-btn"
          :style="{ background: infoList[node].TS ? '#0ef7ff' : '' , color: infoList[node].TS  ? '#25495e' : ''}"
           @click="toIpt(infoList[node].TS, '点击按钮', 'TS', node)"
          >{{ infoList[node].TS ? "跟踪" : "自动" }}</div>
          <div class="btnarr1-btn"
         :style="{ background: infoList.G_MCSOTHER[historyname+'SEL'] ? '#0ef7ff' : '' , color: infoList.G_MCSOTHER[historyname+'SEL'] ? '#25495e' : ''}"
           @click="toIpt(infoList.G_MCSOTHER[historyname+'SEL'], '点击按钮', historyname+'SEL', 'G_MCSOTHER')"
          >{{infoList.G_MCSOTHER[historyname+'SEL'] ? '投用' : '切除'}}</div>
          <div class="btnarr1-btn"
          @click="toIpt(infoList[node].AD, '点击按钮', 'AD', node)"
          >{{ infoList[node].AD ? "反作用" : "正作用" }}</div>
            <!-- <div class="row" v-if="projectData.type == 'rql'">
            <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">PVMD</div>
              <div class="num" @click="toIpt(infoList[node].PVMD, '测量值量程下限', 'PVMD', node)"> {{ infoList[node].PVMD }}</div>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">MD</div>
              <div class="num" @click="toIpt(infoList[node].MD, '输出控制量程下限', 'MD', node)"> {{ infoList[node].MD }}</div>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">OUTB</div>
              <div class="num" @click="toIpt(infoList[node].OUTB, '控制输出下限，该值必须在MD和MU之间', 'OUTB', node)"> {{ infoList[node].OUTB }}</div>
            </div>
            </div>
              <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">ORL</div>
              <div class="num" @click="toIpt(infoList[node].OutRL, '输出最小幅度限幅，该值为MU-MD的百分比', 'OutRL', node)"> {{ infoList[node].OutRL }}</div>
            </div>
            </div>
          </div> -->
             <div class="row" >
            <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">KP</div>
              <div class="num" @click="toIpt(infoList[node].KP, '测量值量程下限', 'KP', node)"> {{ infoList[node].KP }}</div>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">MU</div>
              <div class="num" @click="toIpt(infoList[node].MU, '测量值量程上限', 'MU', node)"> {{ infoList[node].MU }}</div>
            </div>
            </div>
             <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">MD</div>
              <div class="num" @click="toIpt(infoList[node].MD, '输出控制量程下限', 'MD', node)"> {{ infoList[node].MD }}</div>
            </div>
            </div>
              <div class="row-couml flex">
            <div class="list-num flex">
                <div class="text">ORL</div>
              <div class="num" @click="toIpt(infoList[node].ORL, '输出最小幅度限幅，该值为MU-MD的百分比', 'ORL', node)"> {{ infoList[node].ORL }}</div>
            </div>
            </div>
          </div>
        </div>
         </div>
         <!-- 顶部曲线图 -->
         <div class="fristcon-cen-cen">
           <div id="rts_Chart" v-loading="loading" :option="option"></div>
         </div>
         <div class="fristcon-cen-bot">
         <div class="typedata" >
                 <div class="typedatabox" style="color: #1799cd;margin-bottom:1vh" v-if="echarShow[0]">
                <div class="flex">设定值
                </div>
                <div>Y[{{rightarr[0] ? rightarr[0].data : infoList[node].SP }}]</div>
                <div>Y[{{ name[0] ? name[0] : historyname + "_SP" + charAtlet }}]</div>
              </div>
              <div class="typedatabox typedatabox1" style="color: #0de0ac" v-if="echarShow[1]">
                <div>测量值</div>
                <div>Y[{{ rightarr[1] ? rightarr[1].data : infoList[node].PV }}]</div>
                <div>Y[{{ name[1] ? name[1] : historyname + "_PV" + charAtlet }}]</div>
              </div>
              <div class="typedatabox" style="color: #ec163e;marggin-bottom:1vh" v-if="echarShow[2]">
                <div>总输出</div>
                <div>Y[{{rightarr[2] ? rightarr[2].data : infoList[node].AV}}]</div>
                <div>Y[{{ name[2] ? name[2] : historyname + "_AV" + charAtlet}}]</div>
              </div>
                
           </div>
         </div>
      </div>
      <div class="fristcon-cen-btn">
        <div class="btn-f flex">
          <div class="btn-f-btn" @click="serchDt(10)" :class="{current:active == 10}">{{dtname}}</div>
          <div class="btn-f-btn" @click="serchHis(1,6,'up')" :class="{current:active == 6}">前翻一页</div>
            <div class="btn-f-btn" @click="serchHis(0.5,7,'up')" :class="{current:active == 7}">前翻半页</div>
             <div class="btn-f-btn" @click="serchHis(0.5,8,'down')" :class="{current:active == 8}">后翻半页</div>
              <div class="btn-f-btn" @click="serchHis(1,9,'down')" :class="{current:active == 9}">后翻一页</div>
                 <div class="btn-f-btn1" @click="serchHis(1,0)" :class="{current:active == 0}">1h</div>
        <div class="btn-f-btn1" @click="serchHis(2,1)" :class="{current:active == 1}">2h</div>
        <div class="btn-f-btn1" @click="serchHis(4,2)" :class="{current:active == 2}">4h</div>
        <div class="btn-f-btn1" @click="serchHis(8,3)" :class="{current:active == 3}">8h</div>
        <div class="btn-f-btn1" @click="serchHis(24,4)" :class="{current:active == 4}">24h</div>
        <div class="btn-f-btn1" @click="serchHis(72,5)" :class="{current:active == 5}">72h</div>
        </div>
      </div>
      <div class="fristcon-bot">
        <div class="fristcon-bot-row1 flex">

           <div class="row4 flex align-cen">
             <div class="time-tit">指定时间:</div>
              <el-date-picker
              v-model="value2"
              type="datetimerange"
              :picker-options="pickerOptions"
             range-separator="-"
            start-placeholder="开始时间"
            end-placeholder="结束时间"
            format="yyyy-MM-dd HH:mm:ss"
            value-format="yyyy-MM-dd HH:mm:ss"
              >
            </el-date-picker>
            <div class="row4-btn" @click="sech">查询</div>
           </div>
        </div>

      </div>
      </div>
      <inputVal ref="inputVal" @getTreeData="DataJson(arguments)"></inputVal>
    </div>
</template>
<script>
import inputVal from "@/components/inputVal.vue"; //输入框组件
import { downAction } from "@/api/index/index";
import index from "./_index/index.js";
 export default {
  components: {inputVal },
     props:{
        infoList:{
            type:Object,
            default: () =>{
              return {}// 默认值
            } 
        }
    },
    data() {
      return {
         charAtlet:'',
      echarShow:[true,true,true],
        dataview:{},
      Lkname:'',
      Hhistoryname:'',
      Hnode:'',
      isHshow:false,
      historyname:'',
      node:'',
      titname:'',
       xAxis: [],
      echartData: [],
      legendData: [],
      timer: null,
      myChart: "",
      loading: true,
      echartcolor: [],
      end_time: "",
      start_time: "",
      scaleHeight: "",
      name: [],
      name1: [],
      mark: [],
      nodeList: [],
      option:'',
      value2: '',
      havetrue:'',
      current:'current',
      active:10,
      now:true,
      dtname:'实时趋势',
      projectData:{},
      qcty:'',
      jsostr:'',
      addnember:''
      }
    },
    mixins: [index],
      watch: {
    option: {
      handler(newVal, oldVal) {
        if (this.myChart) {
          if (newVal) {
            this.loading = false;
            this.myChart.setOption(newVal, true);
          } else {
            this.loading = false;
            this.myChart.setOption(oldVal);
          }
        } else {
          this.loading = false;
          this.drawLine();
        }
      },
    },
      rightarr1: {
      handler(newVal, oldVal) {
    
        if (this.rightarr) {
          if (newVal) {
            this.rightarr(newVal, true);
          } else {
            this.rightarr(oldVal);
          }
        }
      },
    },
    infoList: {
      handler(n, o) {
        this.infoList = n;
      },
      deep: true, // 深度监听父组件传过来对象变化
    },
    // rightarr
  },
    created(){
    },
    mounted(){
      this.getInfo();
      this.timer = setInterval(() => {
              setTimeout(this.getInfo(), 0);
            }, 5000);
            // 通过$once来监听定时器，在beforeDestroy钩子可以被清除。
            this.$once('hook:beforeDestroy', () => {           
                clearInterval(this.timer);                                    
            })
      if(this.value2){
        this.havetrue = '至'
      }
    },
    methods: {
        toCompon(key, name, name2, name3) {
      this.isIndex = key;
      this.Hhistoryname = name;
      this.Hnode = name2;
      this.Lkname = name3;
      switch (key) {
        case 0:
          return (this.isMshow = true);
        case 1:
          return (this.isFshow = true);
        case 2:
          return (this.isHshow = true);
      }
    },
       isClose1() {
      this.isHshow = false;
    },
      // 时间段查询
      sech(){
        this.start_time = this.value2[0];
        this.end_time = this.value2[1];
        this.getInfo();
      },
      // 历史实时
      serchDt(index){
        this.now = !this.now
        if(this.now ){
           this.active = index
          this.dtname = '实时趋势'
           this.start_time = ""
             this.timer = setInterval(() => {
              setTimeout(this.getInfo(), 0);
            }, 5000);
        }else{
           this.active = '-1'
          this.dtname = '历史趋势'
          this.getInfo()
           clearInterval(this.timer);
        }
      },
        chanereast(val, name, mark, node) {
      this.$confirm(name, {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (typeof val == "boolean") {
            val = !val;
          }
          downAction({
            device: this.projectData.device,
            mark: mark,
            node: node,
            value: val,
            cp_type: this.projectData.type,
          })
            .then((res) => {
              if (res.info.res == 1) {
                this.infoList[node].mark = val
              } else {
                this.$message.error("出错了");
              }
            })
            .catch((err) => {
              this.$message.error("下置失败");
            });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消",
          });
        });
    },
        //  查看过去趋势
  serchHis(val,i,type) {
      if(!this.now){
      //  this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
      // this.start_time = this.$util.getTimeData(val);
      // this.active = i
      // this.getInfo();
        this.value2 = []
             if(type == 'down'){
          if(this.start_time ==""){
            this.$message.warning('已经是最新的了～～');
            return
          }else{
          this.addnember = this.addnember >= 1 ? this.addnember - val : 0
          if(this.addnember != 0){
          this.start_time = this.$util.getTimeData(this.addnember) ;
          this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
          }else{
            this.end_time = "";
          this.start_time = ""
          }
          this.active = i
          }
        }else if(type == 'up'){
          if(this.start_time){
             this.addnember = this.addnember + val
          }else{
            this.addnember = val 
          }
          this.start_time = this.start_time ?  this.$util.getTimeData(this.addnember) : this.$util.getTimeData(val);
          this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
          this.active = i
        }else{
          this.end_time = this.$util.formatDate("yyyy-MM-dd mm:hh:ss");
          this.start_time = this.$util.getTimeData(val);
          this.active = i
        }
        this.getInfo();
      }else{
        this.$message.error("请先切换为历史趋势");
      }
    },
          // 自动手动跟踪
    chaneStu(val, mark, node) {
      
      downAction({
        device: this.projectData.device,
        mark: mark,
        node: node,
        value: val,
        cp_type:this.projectData.type,
      })
        .then((res) => {
          if (res.info.res == 1) {
            this.infoList[node].RM = val
          } else {
          }
        })
        .catch((err) => {});
    },
        // 获取输入框组件传过来的值
       DataJson(data) {
      let strNode = data[0];
      let strMark = data[1];
      this.$nextTick(() => {
        this.infoList[strNode][strMark] = data[2];
      });
    },
       //    打开输入框组件。
    toIpt(data, name, historyname, node) {
      this.$refs.inputVal.open(data, name, historyname, node);
    },
    },
    destroyed() {
        // sessionStorage.removeItem('infoList')
    },
  }
</script>
<style lang="scss" scoped>
.el-main{
  width: 100% !important;
}
.main-top{
    width: 100%;
    height: 5vh;
}
.current{
  color: #0ef7ff !important;
  border: 1px solid #0ef7ff;
  box-sizing: border-box;
}
.fristcon{
  width: 100vw;
  height: 92vh;
  margin-left: -2.5vw;
  overflow: hidden;
  background-image: linear-gradient(
		#042643, 
		#042643), 
	linear-gradient(0deg, 
		#0b385f 3%, 
		#064468 30%, 
		#005071 57%, 
		#045373 74%, 
		#085575 100%);
	background-blend-mode: normal, 
		normal;
  .fristcon-tit{
    width: 53vw;
    height: 5vh;
    background: url("~@/assets/images/ding1.png") no-repeat center center;
    background-size: cover;
    margin: 3px auto;
    text-align: center;
    line-height: 3.5vh;
    color: #0ef7ff;
    font-size: 3vh;
    // border:#0ef7ff 1px solid;
  }
  
  .fl{
    float: left;
  }
  .fristcon-cen {
    .fristcon-cen-left{
      width: 13vw;
       height: 56vh;
      background: rgba(0,22,36,.2);
      // background-image: linear-gradient(to right, rgba(0,22,36,.6) , rgba(0,22,36,.2));
      .fristcon-cen-left-tit{
        width: 12vw;
        height: 4vh;
        text-align: center;
        color: #22465b;
        font-size: 1vw;
        background-color: #fff272;
        line-height: 4vh;
      }
      .btnarr{
        margin-top: 4vh;
      .fristcon-cen-left-btn{
              width: 7vw;
              margin: 0 auto;
              height: 6vh;
                background: url("~@/assets/images/xk2.png") no-repeat center center;
              background-size: 100% 100%;
              margin-top: 3vh;
             
              .text{
                color: #6acafb;
                font-size: 1vw;
                line-height: 3vh;
                padding-left: 1vw;
                 cursor: pointer;
              }
              .num{
                color: #eafeff;
                font-size: 1vw;
                padding-right: 0.5vw;
                 cursor: pointer;
                
              }
            }
            }
            .btnarr1{
              margin-top: 4vh;
              .btnarr1-btn{
                width: 4vw;
                background: rgba(14,247,255,.2);
                height: 3vh;
                text-align: center;
                line-height: 3vh;
                color: #0ef7ff;
                font-size: 1vw;
                margin: 0 auto;
                margin-top: 1vh;
                border-radius: 2px;
                 cursor: pointer;
              }
            }
      
    }
    .fristcon-cen-cen{
  width: 82vw;
      height: 72vh;
      border:#0ef7ff 1px solid
    }
    #rts_Chart{
    width: 100%;
    height: 72vh;
    }
    .fristcon-cen-bot{
      width: 9vw;
      height: 72vh;
       margin-left: 1vw;
      background: rgba(0,22,36,.2);
      .typedata{
        width: 8vw;
        height: inherit;
        margin-left: 1.5vw;
        margin-top: 1vh;
       .typedatabox{
         div{
           	font-family: 'MicrosoftYaHei';
	            font-size: 0.8vw;
              line-height: 2vh;
         }
       }
      }
    }
  }

  .fristcon-cen-btn{
    width: 100%;
    margin-top:2vh;
    margin-left:-4vw;
    .btn-f{
      width: 60vw;
      height: 3vh;
      margin: 1vh auto;
      .btn-f-btn{
        width: 5vw;
        background: rgba(14, 247, 255, 0.2);
        border-radius: 2px;
        color: rgba(14, 247, 255, 0.5);
        text-align: center;
        line-height: 3vh;
        font-size: 1vw;
        margin-left: 1vw;
         cursor: pointer;
      }
            .btn-f-btn1{
        width: 2vw;
        background: rgba(14, 247, 255, 0.2);
        border-radius: 2px;
        color: rgba(14, 247, 255, 0.5);
        text-align: center;
        line-height: 3vh;
        font-size: 1vw;
        margin-left: 1vw;
         cursor: pointer;
      }
      .btn-f-btn:first-child{
        margin-left: 0;
       
      }
    }
  }
        .row{
        width:9.6vw;
        height: 19vh;
        margin-top:11vh;
        background: url("~@/assets/images/xk3.png") no-repeat center center;
        background-size: 100% 100%;
         margin-left: 1vw;
        .row-couml{
          padding: 0 0.8vw;
          line-height: 4.5vh;
          .list-num{
            font-family: 'ArialNarrow';
            width: 50%;
            
            .text{
              color: #6acafb;
            font-size: 1vw;
            width: 40%;
            text-align: right;
            }
            .num{
              color: rgba(228,254,255,.7);
              font-size: 1vw;
              padding-left: 1vw;
              cursor: pointer;
            }
          }
          .list-num1{
            width: 50%;
              .text{
              color: #6acafb;
             font-size: 1.5vh;
            width: 40%;
            text-align: right;
            }
          }
        }
        .row-couml:first-child{
          padding-top: 0.8vh;
        }
      }
  .fristcon-bot{
    width: 5vw;
    margin: 0 auto;
    margin-top:-8vh;
    
    .fristcon-bot-row1{
      // position: relative;
      margin-left:-10vw;

      .row:first-child{
        margin-left: 0;
      }
      .row1{
        width: 13vw;
        height: 19vh;
        margin-left: 1vw;
        .row1-top{
          width: 100%;
          height: 9vh;
           background: url("~@/assets/images/xk1.png") no-repeat center center;
        background-size: 100% 100%;
           .row-couml{
          padding: 0 0.8vw;
          line-height: 3vh;
             .list-num{
            font-family: 'ArialNarrow';
            width: 50%;
            
            .text{
              color: #6acafb;
             font-size: 1.5vh;
            width: 40%;
            text-align: right;
            }
            .num{
              color: #e4feff;
               font-size: 1.5vh;
              padding-left: 1vw;
              cursor: pointer;
            }
          }
          .list-num1{
            width: 50%;
              .text{
              color: #6acafb;
             font-size: 1.5vh;
            width: 40%;
            text-align: right;
            }
          }
        }
       
        }
                .row1-top1{
           .text{
              color: #6acafb;
             font-size: 1.5vh;
            width: 2vw;
            text-align: right;
            line-height: 3vh;
            }
        }
      }
      .row2{
        width: 13vw;
        height: 19vh;
        margin-left: 1vw;
          background: url("~@/assets/images/xk3.png") no-repeat center center;
        background-size: 100% 100%;
        .list1{
          width: 25%;
          text-align: center;
          padding-top: 2vh;
          line-height: 3vh;
          div{
            font-size: 1.5vh;
            color: #e4feff;
             cursor: pointer;
          }
          .list-tit{
             color: #6acafb;
             
          }
        }
      }
      .row-5{
        .row-couml{
          line-height: 3.5vh;
        }
      }
      .row3{
        width: 27vw;
        height: 15vh;
         background: url("~@/assets/images/xk3.png") no-repeat center center;
        background-size: 100% 100%;
        margin-left: auto;
     
        .list-num{
          width: auto !important;
        }
        .text{
          padding-left: 1vw;
        }
      }
      .row4{
        // position: absolute;
        // right: 0;
        // bottom: 0;
          margin-top:4vh;
        .time-tit{
          color: #0c78a3;
          font-size: 1vw;
          margin-left:30vw;
          width:5vw;
        
        }
        .row4-btn{
          width: 4vw;
          height: 3vh;
           background: rgba(14, 247, 255, 0.2);
           border-radius: 2px;
          color: rgba(14, 247, 255, 0.5);
          text-align: center;
          line-height: 3vh;
          font-size: 1vw;
          margin-left: 10px;
        }
        .el-range-editor.el-input__inner{
          background: #031d30 !important;
          border: 1px solid #064a6d;
          width: 16vw;
          height: 3vh;
         padding: 3px 5px;
         position: relative;
         z-index: 1;
        }
       
      }
    }
    .fristcon-bot-row2{
      // position: relative;
      // margin: 1vw auto;
      margin-left:-10vw;
      .fristcon-bot-row2-left1 {
        width: 28vw;
        background-color: #031d30;
        div{
          color: #397ca3;
          height: 3vh;
          line-height: 3vh;
          font-size: 1.5vh;
          width: 4vw;
          text-align: center;
          position: relative;
        }
        div::after{
           content: '';
           position: absolute;
           height: 1.4vh;
           width: 1px;
           background: #397ca3;
           right: 0;
           top: 0.8vh;
        }
        div:last-child::after{
          content: none;
        }
      }
      .fristcon-bot-row2-left2{
        width: 42vw;
        height: 1vh;
        background: url("~@/assets/images/bot.png") no-repeat center center;
        background-size: 100% 100%;
        margin-left: 5vw;
        .list-flex1-btn{
         width: 5vw;
         height: 3vh;
          background-color: rgba(14,247,255,.2);
          border-radius: 2px;
        line-height: 3vh;
        text-align: center;
        cursor: pointer;
        font-size: 1vw;
        color: #209caf;
        margin-left: 15.6vw;
        margin-top: -0.8vh;
        }
        .list-flex1-btn1{
          margin-left: 21.4vw;
          margin-top: -3vh;
        }
      }
       .condition-time {
        
      div {
        width: 2vw;
        height: 3vh;
        background-color: rgba(14,247,255,.2);
        border-radius: 2px;
        line-height: 3vh;
        text-align: center;
        font-size: 1vw;
        color: #209caf;
        margin-right: 3px;
        cursor: pointer;
      }
    }
    }
  }
}
::v-deep{
  .el-range__icon,.el-range__close-icon{
            line-height: 2vh !important;
          }
          .el-range__close-icon{position: absolute;
          z-index: 2;
          right: 0;
          }
          .el-range__icon{display: none;}
          .el-range-input{
             background: #031d30 !important;
             color: #fff;
             width: 42%;
             font-size: 0.8vw;
          }
          .el-range-separator{color: #fff;line-height: 2vh;}
}
</style>